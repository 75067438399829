export type OfferType =
  | 'HOT_OFFER'
  | 'BINANCE_CASHBACK_OFFER'
  | 'POW_OFFER'
  | 'INVITE_FRIEND_OFFER'
  | 'SO_QUEST_OFFER';

export const CATEGORIES = {
  SOCIAL_MEDIA: 'SOCIAL_MEDIA',
  CONTENT_CREATION: 'CONTENT_CREATION',
  VOTE: 'VOTE',
  BOUNTIES: 'BOUNTIES',
  AIRDROP: 'AIRDROP',
  MAGIC_STORE_TASKS: 'MAGIC_STORE_TASKS',
  LEARN_2_EARN: 'LEARN_2_EARN',
} as const;

export const MEMBERSHIP_TIERS = {
  FREE: 'FREE',
  PLUS: 'PLUS',
  PREMIUM: 'PREMIUM',
  PREMIUM_PLUS: 'PREMIUM_PLUS',
};

export const categoriesOptions = [
  {
    value: '0',
    label: CATEGORIES.SOCIAL_MEDIA,
  },
  {
    value: '1',
    label: CATEGORIES.CONTENT_CREATION,
  },
  {
    value: '2',
    label: CATEGORIES.VOTE,
  },
  {
    value: '3',
    label: CATEGORIES.BOUNTIES,
  },
  {
    value: '4',
    label: CATEGORIES.AIRDROP,
  },
  {
    value: '5',
    label: CATEGORIES.MAGIC_STORE_TASKS,
  },
  {
    value: '6',
    label: CATEGORIES.LEARN_2_EARN,
  },
];

export const MEMBERSHIP_TIER = {
  SOCIAL_MEDIA: 'SOCIAL_MEDIA',
  CONTENT_CREATION: 'CONTENT_CREATION',
  VOTE: 'VOTE',
  BOUNTIES: 'BOUNTIES',
  AIRDROP: 'AIRDROP',
  MAGIC_STORE_TASKS: 'MAGIC_STORE_TASKS',
  LEARN_2_EARN: 'LEARN_2_EARN',
} as const;

export type Variant = 'create' | 'restore' | 'delete';

export const DEVELOPERS = {
  DEVELOPER_ONE: 'DEVELOPER 1',
  DEVELOPER_TWO: 'DEVELOPER 2',
  DEVELOPER_THREE: 'DEVELOPER 3',
  DEVELOPER_FOUR: 'DEVELOPER 4',
  DEVELOPER_FIVE: 'DEVELOPER 5',
  DEVELOPER_SIX: 'DEVELOPER 6',
  DEVELOPER_SEVEN: 'DEVELOPER 7',
} as const;

export type Conditions = {
  staking: {
    enabled: boolean;
    sqrAmount: number;
  };
  swapSqr: {
    enabled: boolean;
    usdAmount: number;
  };
  membership: {
    tier: 'FREE' | 'PREMIUM' | 'PLUS' | 'PREMIUM PLUS';
    enabled: boolean;
  };
  swapNonSqr: {
    enabled: boolean;
    usdAmount: number;
  };
  purchaseSqr: {
    enabled: boolean;
    usdAmount: number;
  };
  purchaseNonSqr: {
    enabled: boolean;
    usdAmount: number;
  };
  launchpadInvest: {
    enabled: boolean;
    usdAmount: number;
  };
};

export interface UpdateMagicBoardData {
  app_id: string | null;
  name: string;
  offer: string;
  how_to_get: string;
  start_date: number | null;
  end_date: number | null;
  position: number | null;
  category_ids: string[];
  developer_id: number | null;
  developer_name: string | null;
  seo_title: string;
  seo_description: string;
  seo_keywords: string;
  karma_reward: number | null;
  sqr_reward: number | null;
  min_karma_limit: number | null;
  min_membership_tier: string;
  offer_params: {
    link: {
      enabled: boolean;
      explanation: string;
    };
    text: {
      enabled: boolean;
      explanation: string;
    };
    file: {
      enabled: boolean;
      explanation: string;
    };
    invites_number: number | null;
    karma_limit_invitee: number | null;
    approval_type: 'manual' | 'automatic';
    sq_campaign_id: string;
    conditions: Conditions;
  };
  offer_amount: string;
  offer_type: string;
  offer_price: number | null;
  offer_currency: string;
  face_currency: string;
  lock_period: number | null;
  cashback_percentage: number | null;
  index: number;
  cashback_description: string;
  pool_amount: number | null;
  status: string;
  id: number;
  claim_karma_threshold: number | null;
  payout_tokens: {
    id: string;
    amount: number;
    current: boolean;
  }[];
  claims_limit: number | null;
  claims_per_user_limit: number | null;
  priority_access: boolean;
  meta_robots: boolean;
  magic_wall: boolean;
  token_reward_disabled: boolean;
}

export interface ManageMagicBoardData {
  app_id: number | null;
  deleted: boolean;
  id: number;
}

export interface MagicBoardData {
  rows: UpdateMagicBoardData[];
  count: 0;
}

export type MagicBoardSearchParams = {
  limit?: number;
  type?: OfferType;
  offset?: number;
};

export type MagicBoardSearchDevelopersParams = {
  limit?: number;
  offset?: number;
  name?: string;
  id?: number;
  creatorId?: string;
};

export interface MagicBoardSearchDeveloper {
  id: number;
  name: string;
  icon: string;
  developer_name_id: string;
}

export interface MagicBoardSearhcDeveloperData {
  rows: MagicBoardSearchDeveloper[];
  count: number;
}
